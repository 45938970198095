<template>
  <router-view></router-view>
</template>
<script charset="utf-8">
// global
import { mapState } from 'vuex'

export default {
  name: 'VendorFit',
  created() {
    document.title = 'FIN VendorFit'
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ div[disabled='disabled'] {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
